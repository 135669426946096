import './product-overview-slider.scss';

import Swiper from 'swiper';
import { Navigation, A11y, Pagination } from 'swiper/modules';

class ProductSlider {
    constructor ($element, options) {
        const defaults = {
            initAttr: 'data-product-overview',
            sliderAttr: 'slider',
            slideAttr: 'slide'
        };

        this.settings = Object.assign({}, defaults, options);
        this.$productSlider = $element;
        this.$productSliderContainer = this.$productSlider.querySelector(`[${this.settings.initAttr}="${this.settings.sliderAttr}"]`);
        this.$productSlides = this.$productSlider.querySelectorAll(`[${this.settings.initAttr}="${this.settings.slideAttr}"]`);
        this.productSlider = null;

        this.initialize();
    }

    initialize () {
        if (this.$productSliderContainer && this.$productSlides.length > 1) {
            if (!window.mediaMatchHandler[1024].matches) {
                this.initSlider();
            } else {
                if (this.$productSlides.length < 4) {
                    this.destroySlider();
                } else {
                    this.initSlider();
                }
            }

            window.mediaMatchHandler[1024].addEventListener('change', (match) => {
                if (!match.matches) {
                    this.initSlider();
                } else {
                    if (this.$productSlides.length < 4) {
                        this.destroySlider();
                    } else {
                        this.initSlider();
                    }
                }
            });
        }
    }

    initSlider () {
        this.productSlider = new Swiper(this.$productSliderContainer, {
            autoHeight: true,
            modules: [Navigation, Pagination, A11y],
            speed: 500,
            slidesPerView: 'auto',
            centeredSlides: true,
            centeredSlidesBounds: true,
            observer: true,
            observeParents: true,
            a11y: {
                prevSlideMessage: 'Vorheriger Slide',
                nextSlideMessage: 'Nächster Slide',
                paginationBulletMessage: 'Springe zu Slide {{index}}',
                firstSlideMessage: 'Dies ist der Erste Slide',
                lastSlideMessage: 'Dies ist der Letzte Slide',
                slideLabelMessage: 'Slide {{index}} von {{slidesLength}}'
            },
            pagination: {
                el: '.swiper-pagination',
                type: 'bullets',
                clickable: true
            },
            navigation: {
                prevEl: '.swiper-button-prev',
                nextEl: '.swiper-button-next'
            },
            breakpoints: {
                1024: {
                    autoHeight: false,
                    centeredSlides: false,
                    centeredSlidesBounds: false
                }
            }
        });
    }

    destroySlider () {
        if (this.productSlider !== null) {
            this.productSlider.destroy(true, true);
            this.productSlider = null;
        }
    }
}

export { ProductSlider };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $$productSlider = $context.querySelectorAll('[data-product-overview="root"]');
        for (let i = 0; i < $$productSlider.length; i++) {
            const $productSlider = $$productSlider[i];

            $productSlider.API = new ProductSlider($productSlider);
        }
    }
});
